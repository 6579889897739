import React from "react";
import Text from "../../components/data-display/text";
export default function QuikTasksection(classes) {
    const email = 'Privacy@Quicktask.co.uk';

  return (
    <div style={{padding: "10vh 16px"}}>
      <Text variant={"body1"} medium className={classes.subHeading}>
        Effective: 15th January 2024
      </Text>
      <div style={{height: "24px"}} />
      <Text variant={"h6"} bold className={classes.subHeading}>
        1. Introduction
      </Text>
      <Text variant={"body1"} regular>
        QuickTask (“we,” “us,” or “our”) is committed to safeguarding the privacy and security of
        your personal information. This GDPR Privacy Policy outlines how we collect, use, and
        protect your data in compliance with the General Data Protection Regulation (GDPR).
      </Text>
      <div style={{height: "24px"}} />
      <Text variant={"h6"} bold className={classes.subHeading}>
        2. Information We Collect
      </Text>
      <Text variant={"body1"} regular>
        We may collect a range of personal information, including but not limited to:
        <ul>
          <li>
            Your personal identification details, including name, address, email address, phone
            number, and other contact information.
          </li>

          <li> Birth date and gender information</li>

          <li>
            Payment details, such as credit card and account information, securely processed by a
            third-party service provider handling payments on our behalf.
          </li>

          <li>
            Location details, encompassing both your current location and the area where you wish to
            perform tasks.
          </li>

          <li>
            Occupational information, including your occupation, work experience, resume,
            qualifications, education, post tasks, earn money preferences, skillset, interests, and
            other relevant details regarding your fitness for tasks.
          </li>
        </ul>
      </Text>
      <div style={{height: "24px"}} />
      <Text variant={"h6"} bold className={classes.subHeading}>
        3. How We Use Your Information{" "}
      </Text>
      <Text variant={"body1"} regular>
        We process your information for various purposes, including:
        <ul>
          <li> Facilitating task processing and communication</li>
          <li> Conducting payment transactions (if applicable)</li>
          <li> Providing support and resolving issues</li>
          <li> Enhancing our services and user experience</li>
        </ul>
      </Text>
      <div style={{height: "24px"}} />
      <Text variant={"h6"} bold className={classes.subHeading}>
        4. Legal Basis for Processing{" "}
      </Text>
      <Text variant={"body1"} regular>
        The processing of personal data is based on legal grounds such as:
        <ul>
          <li> Performance of a contract between you and QuickTask</li>
          <li> Compliance with legal obligations</li>
          <li> Consent obtained from you (where applicable)</li>
          <li> Legitimate interests pursued by QuickTask or a third-party</li>
        </ul>
      </Text>
      <div style={{height: "24px"}} />
      <Text variant={"h6"} bold className={classes.subHeading}>
        5. Data Security{" "}
      </Text>
      <Text variant={"body1"} regular>
        <ul>
          We implement stringent security measures to protect your data from unauthorised access,
          disclosure, alteration, and destruction. Our commitment is to ensure the confidentiality
          and integrity of your information.
        </ul>
      </Text>
      <div style={{height: "24px"}} />
      <Text variant={"h6"} bold className={classes.subHeading}>
        6. Data Sharing{" "}
      </Text>
      <Text variant={"body1"} regular>
        In specific situations, we may share your information with:
        <ul>
          <li> Task performers to fulfil your requests</li>
          <li> Legal authorities if required by law</li>
          <li> Third-party service providers assisting in our operations</li>
        </ul>
      </Text>

      <div style={{height: "48px"}} />
      <Text variant={"h6"} bold className={classes.subHeading}>
        7. International Data Transfers{" "}
      </Text>
      <Text variant={"body1"} regular>
        Your data may be transferred and processed outside the European Economic Area (EEA). Such
        transfers adhere to GDPR requirements, ensuring the protection of your personal information.
      </Text>

      <div style={{height: "48px"}} />
      <Text variant={"h6"} bold className={classes.subHeading}>
        8. Data Retention{" "}
      </Text>
      <Text variant={"body1"} regular>
        <ul>
          We retain your data only for as long as necessary to achieve the purposes outlined in this
          policy or as required by applicable laws.
        </ul>
      </Text>

      <div style={{height: "48px"}} />
      <Text variant={"h6"} bold className={classes.subHeading}>
        9. Your Rights{" "}
      </Text>
      <Text variant={"body1"} regular>
        Under GDPR, you have the right to:
        <ul>
          <li> Access your data</li>
          <li> Correct inaccuracies in your data</li>
          <li> Erase your data under certain circumstances</li>
          <li> Object to processing based on legitimate interests</li>
          <li> Data portability for certain data</li>
          <li> Withdraw consent (where applicable)</li>
        </ul>
      </Text>

      <div style={{height: "48px"}} />
      <Text variant={"h6"} bold className={classes.subHeading}>
        10. Cookies and Similar Technologies{" "}
      </Text>
      <Text variant={"body1"} regular>
        QuickTask employs cookies and comparable tracking technologies to serve various purposes,
        such as accessing your information upon signing in, managing your preferences, delivering
        specific content, reporting on our user base, and enhancing our services. Similar
        technologies, including cookies or anonymous identifiers, come into play when interacting
        with our affiliate service providers.
        <br />
        Our use of cookies falls into the following categories:
        <Text
          variant={"body1"}
          regular
          style={{fontWeight: "bold", fontSize: "18px", marginTop: "10px", marginLeft: "5px"}}
        >
          Functionality Cookies:
          <Text variant={"body1"} regular>
            QuickTask utilises cookies and similar technologies to provide enhanced functionality,
            such as recognizing you upon sign-in, maintaining your signed-in status during browsing,
            and tracking your specific preferences, interests, or viewed items. This enables us to
            enhance the presentation of content on our website and mobile app.
          </Text>
        </Text>
        <Text
          variant={"body1"}
          regular
          style={{fontWeight: "bold", fontSize: "18px", marginTop: "10px", marginLeft: "5px"}}
        >
          Performance Analytics Cookies:
          <Text variant={"body1"} regular>
            These cookies and similar technologies evaluate the Services' performance, helping us
            understand user behaviour and improve content or layout. We analyse information on how
            you and other visitors use the Services to enhance features and track visitor numbers.
          </Text>
        </Text>
        <Text
          variant={"body1"}
          regular
          style={{fontWeight: "bold", fontSize: "18px", marginTop: "10px", marginLeft: "5px"}}
        >
          Advertising Cookies:
          <Text variant={"body1"} regular>
            QuickTask may employ first-party or third-party cookies and similar technologies to
            deliver content, including ads relevant to your interests. This involves understanding
            the usefulness of advertisements and content delivered to you, such as tracking whether
            you've interacted with an advertisement.
          </Text>
          <Text variant={"body1"} regular>
            You have the option to reject first-party advertising cookies and similar technologies
            through your browser settings. To learn more about using cookies or other technologies
            for advertising relevance and to control or opt out of data collection by third-party
            tools, refer to our [privacy policy]
          </Text>
        </Text>
      </Text>
      <div style={{height: "48px"}} />
      <Text variant={"h6"} bold className={classes.subHeading}>
        11. Updates to this Policy{" "}
      </Text>
      <Text variant={"body1"} regular>
        This GDPR Privacy Policy may be updated periodically. We encourage you to review the policy
        regularly for any changes.
      </Text>
      <div style={{height: "48px"}} />
      <Text variant={"h6"} bold className={classes.subHeading}>
        12. Contact Information{" "}
      </Text>
      <Text variant={"body1"} regular>
        If you have questions or concerns about this policy, please contact our Data Protection
        Officer at:
        <br />
       <p style={{ marginTop: "20px" }}>
      Email:<a href={`mailto:${email}`} style={{ color: "#26A2A7" }}>{email}</a>
    </p>
      </Text>
    </div>
  );
}
