import React from 'react'
import {Container} from "@mui/material";
import SEO from "../../components/seo/seo";
import Text from "../../components/data-display/text";
import InlineLink from "../../components/data-display/inline-link";
import {project, title, isQuickTaskProject} from "../../utils/constant.urls";
import SydeTaskerSection from "./SydeTaskersection";
import QuikTasksection from "./QuikTasksection";

const styles = {
  subHeading: {
    marginBottom: "12px",
  },
};

const PrivacyPolicy = () => {
  return (
    <>
      <SEO
        description={`${title} will solely share and disclose your data in accordance with your instructions, including any applicable terms in the Terms of Service, and in compliance with applicable law and legal process.`}
        title={"Privacy Policy"}
      />
      <div
        style={{
          width: "100%",
          background: "rgb(235,235,235)",
          height: "30vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{height: "64px"}} />
        <Text variant={"h1"} black style={{textAlign: "center"}}>
          {"Privacy Policy"}
        </Text>
      </div>
      <Container fixed maxWidth={"md"}>
        {!isQuickTaskProject ? <SydeTaskerSection /> : <QuikTasksection />}
      </Container>

      <div style={{height: "1px", width: "100%", background: "rgb(220,220,220)"}} />
    </>
  );
};

export default PrivacyPolicy